<template lang="pug">
#app
  v-btn(@click="showModal", color="bt-primary", :disabled="disabled", :loading="loading").white--text {{ trans['cropImage'] }}
  v-dialog(v-model="show", max-width="800px", width="auto")
    v-card(v-if="show")
      v-card-title.title {{ trans['cropImage'] }} ({{ width }}x{{ height }})
      v-card-text
        v-container(grid-list-md)
          v-layout(wrap, row)
            v-flex.xs12(v-if="size && loaded")
              vue-croppie(ref="croppieRef", :enableResize="false", :enableOrientation="true", :mouseWheelZoom="true", :boundary="size", :viewport="size", @result="result", @update="update")
          v-layout(wrap, row)
            v-flex.xs12.text-xs-center.content
              v-btn(type="button", color="bt-primary", @click="emitUpload").white--text {{ trans['chooseImage'] }}
              v-btn(v-if="image", type="button", color="grey", @click="rotate(90)")
                i.fa.fa-undo.white--text
              v-btn(v-if="image", type="button", color="grey", @click="rotate(-90)")
                i.fa.fa-undo.white--text(style="-moz-transform: scaleX(-1); -o-transform: scaleX(-1); -webkit-transform: scaleX(-1); transform: scaleX(-1);")
              v-btn(v-if="image", type="button", color="bt-primary darken-3", @click="cropeImage").white--text {{ trans['confirmCutting'] }}
              input(type="file", ref="upload", value="Choose a file", style="display:none", accept="image/*", v-on:change="setUpFileUploader")
      v-card-actions
        v-spacer
        v-btn(type="button", flat, color="gray darken-1", @click.native="hide") Fechar
</template>

<script>
import $ from 'jquery';

export default {
  props: {
    'defaultImage': {
      type: String,
      default: ''
    },
    'height': {
      type: Number,
      default: 400
    },
    'width': {
      type: Number,
      default: 400
    },
    'circle': {
      type: Boolean,
      default: false
    },
    'disabled': {
      type: Boolean,
      default: false
    },
    'loading': {
      type: Boolean,
      default: false
    },
    'trans': {
      type: Object,
      default: function () {
        return { 
          'cropImage': 'SELECIONAR IMAGEM', 
          'chooseImage': 'ESCOLHER IMAGEM', 
          'confirmCutting': 'CONFIRMAR'
        };
      }
    }
  },
  data () {
    return {
      image: null,
      show: false,
      cropped: false,
      size: {
        width: 400,
        height: 400
      },
      loaded: false
    };
  },
  mounted () {
    let vm = this;
    vm.$on('imgUploaded', function (imageData) {
      vm.image = imageData;
      vm.$refs.croppieRef.bind({
        url: vm.image
      });
    });
    vm.image = vm.defaultImage;
  },
  methods: {
    showModal () {
      let vm = this;
      vm.show = true;
      $('.v-dialog').ready(function(){
        if($('.content').length){
          var proporcao = (vm.width > $('.content')[0].clientWidth) ? (($('.content')[0].clientWidth / vm.width) - 0.15) : 1;
          vm.size = {
            width: vm.width * proporcao,
            height: vm.height * proporcao,
          };
        }
      });
    },
    hide () {
      this.show = false;
    },
    emitUpload () {
      this.$refs.upload.click();
    },
    cropeImage () {
      this.$emit('update:defaultImage', this.cropped);
      this.show = false;
    },
    setUpCroppie () {
      this.$refs.croppieRef.bind({
        url: this.image
      });
    },
    setUpFileUploader (e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },
    createImage (file) {
      let image = new Image();
      let reader = new FileReader();
      let vm = this;

      reader.onload = (e) => {
        vm.loaded = true;
        setTimeout(() => {
          vm.image = e.target.result;
          vm.$emit('imgUploaded', e.target.result);
        }, 500);
      };
      reader.readAsDataURL(file);
    },
    bind () {
      let url = this.images[Math.floor(Math.random() * 4)];
      this.$refs.croppieRef.bind({
        url: url,
      });
    },
    crop () {
      let block = this.image.split(';');
      let contentType = block[0].split(':')[1];
      let options = {
        format: contentType, 
        circle: this.circle,
        size: 'original',
      };
      this.$refs.croppieRef.result(options, (output) => {
        this.cropped = output;
      });
    },
    result (output) {
      this.cropped = output;
    },
    update (val) {
      this.crop();
    },
    rotate (rotationAngle) {
      // Rotates the image
      this.$refs.croppieRef.rotate(rotationAngle);
    },
  },
};
</script>

<style lang="scss" scoped>
.cr-viewport{
  border-color: rgba(0,0,0,0.5) !important;
}
</style>